import React, { useRef, useEffect, useState } from 'react';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler.css';
import { ReactComponent as Cross } from '../../../assets/icons/facesheet_cross.svg'
import StaffManager from '../StaffManager/StaffManager';

const AppointmentSetter = ({ events, showModal, hospitalName, cancelAppointment }) => {
    const schedulerContainer = useRef(null);
    const schedulerInstance = useRef(null);
    const [showStaffModal, setStaffModal] = useState(false);
    const staffUsers = useRef([]);

    useEffect(() => {
        if (sessionStorage.getItem('refreshScheduler') === 'true') {
            sessionStorage.removeItem('refreshScheduler');
            window.location.reload();
        } else {
            if (typeof window.scheduler !== 'undefined') {
                schedulerInstance.current = window.scheduler;
                configureScheduler();
                initScheduler();
            } else {
                console.log('Scheduler is not available on window object');
            }
        }
    }, []);

    const getStaffUsers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/staff-members`, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            const data = await response.json();
            staffUsers.current = data.map(member => ({
                key: `${member.id}`,
                label: `${member.name}:${member.staff_email}`,
            }));
        } catch (error) {
            console.log('Error fetching staff members:', error);
        }
    };

    const updateScheduledByOptions = () => {
        const scheduler = schedulerInstance.current;
        if (!scheduler) {
            console.log('Scheduler instance is null in updateScheduledByOptions');
            return;
        }

        const scheduledBySection = scheduler.formSection("Scheduled by");
        if (scheduledBySection) {
            scheduledBySection.control.innerHTML = ''; // Clear existing options
            staffUsers.current.forEach(option => {
                const optionElement = document.createElement('option');
                optionElement.value = option.key;
                optionElement.textContent = option.label;
                scheduledBySection.control.appendChild(optionElement);
            });
        }
    };

    const configureScheduler = async () => {
        await getStaffUsers();
        const scheduler = schedulerInstance.current;
        if (!scheduler) {
            console.log('Scheduler instance is null in configureScheduler');
            return;
        }

        scheduler.form_blocks["my_link"] = {
            render: function (sns) {
                return "<div id='manageDetails' class='dhx_cal_ltext manage-staff-link' style='height:30px;text-align: center;'>Add/Remove Scheduler Details</div><div style='height:30px;text-align: center;' id='refreshUsers' class='manage-staff-link'>Refresh Scheduler List</div>";
            },
            set_value: function (node, value, ev) { },
            get_value: function (node, ev) {
                return "";
            },
            focus: function (node) { }
        };

        scheduler.config.readonly = true;
        scheduler.config.dblclick_create = false;

        scheduler.config.lightbox.sections = [
            { name: "description", height: 50, map_to: "text", type: "textarea" },
            { name: "time", height: 72, type: "time", map_to: "auto" }
        ];

        scheduler.config.lightbox.sections.push({
            name: "provider",
            map_to: "provider",
            type: "textarea",
            readonly: true
        });

        scheduler.config.lightbox.sections.push({
            name: "provider email",
            map_to: "email",
            type: "textarea",
            readonly: true
        });

        scheduler.config.lightbox.sections.push({
            name: "phone",
            map_to: "phone",
            type: "textarea",
            readonly: true
        });

        scheduler.config.lightbox.sections.push({
            name: "Schedule Appointment",
            map_to: "booking",
            type: "checkbox",
            options: [{ key: true, label: "Booking" }]
        });

        scheduler.config.lightbox.sections.push({
            name: "PatientName",
            height: 30,
            map_to: "patientname",
            type: "textarea"
        });

        scheduler.config.lightbox.sections.push({
            name: "Reason for visit",
            height: 30,
            map_to: "reasonforvisit",
            type: "select",
            options: [
                { key: "", label: "" },
                { key: "Mood Evaluation", label: "Mood Evaluation" },
                { key: "Depression/Anxiety", label: "Depression/Anxiety" },
                { key: "Adjustment Disorder", label: "Adjustment Disorder" },
                { key: "Schizo/Pyschosis", label: "Schizo/Pyschosis" }
            ]
        });

        scheduler.config.lightbox.sections.push({
            name: "Room number",
            height: 30,
            map_to: "roomnumber",
            type: "textarea"
        });

        scheduler.config.lightbox.sections.push({
            name: "comments",
            height: 30,
            map_to: "comments",
            type: "textarea"
        });

        scheduler.config.lightbox.sections.push({
            name: "Scheduled by",
            height: 30,
            map_to: "scheduledby_id",
            type: "select",
            options: staffUsers.current
        });

        scheduler.config.lightbox.sections.push(
            { name: "my_link", height: 30, type: "my_link", map_to: "auto" }
        );

        scheduler.config.lightbox.sections.push({
            name: "user_id",
            height: 30,
            map_to: "user_id",
            type: "textarea",
        });

        scheduler.config.lightbox.sections.push({
            name: "hospital",
            default_value: hospitalName,
            map_to: "hospital",
            type: "textarea"
        });

        scheduler.attachEvent("onBeforeDrag", function (id, mode, e) {
            if (id) {
                const event = scheduler.getEvent(id);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                if (event.start_date < today) {
                    return false; // Prevent dragging past events
                }
            }
            return !!id; // Allow dragging only for existing events
        });

        scheduler.attachEvent("onClick", function (id, e) {
            if (id) {
                scheduler.showLightbox(id);
            }
            return false; // Prevent default behavior
        });

        scheduler.attachEvent("onLightbox", function (id) {

            try {

                const event = scheduler.getEvent(id);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const manageDetailsElement = document.getElementById('manageDetails');
                if (manageDetailsElement) {
                    manageDetailsElement.addEventListener('click', () => setStaffModal(true));
                }

                const refreshUsersElement = document.getElementById('refreshUsers');
                if (refreshUsersElement) {
                    refreshUsersElement.addEventListener('click', () => refreshUsers());
                }

                const providerSection = scheduler.formSection("provider");
                if (providerSection && providerSection.control) {
                    providerSection.control.disabled = true;
                }

                const providerEmailSection = scheduler.formSection("provider email");
                if (providerEmailSection && providerEmailSection.control) {
                    providerEmailSection.control.disabled = true;
                }

                const descriptionSection = scheduler.formSection("description");
                if (descriptionSection && descriptionSection.control) {
                    descriptionSection.control.disabled = true;
                }

                const phoneSection = scheduler.formSection("phone");
                if (phoneSection && phoneSection.control) {
                    phoneSection.control.disabled = true;
                }

                const saveButtons = document.getElementsByClassName('dhx_btn_set dhx_left_btn_set dhx_save_btn_set');
                saveButtons[0].innerHTML = 'Submit';

                const reasonforvisitSection = scheduler.formSection("Reason for visit");
                const roomnumberSection = scheduler.formSection("Room number");
                const patientnameSection = scheduler.formSection("PatientName");
                const commentsSection = scheduler.formSection("comments");
                const scheduledBySection = scheduler.formSection("Scheduled by");
                const emailSection = scheduler.formSection("Email");

                const timeSection = document.querySelector(".dhx_section_time");

                if (timeSection) {
                    const timeSelects = timeSection.querySelectorAll("select"); // Select all 'select' elements within the time section

                    timeSelects.forEach((select) => {
                        select.disabled = true;
                    });
                }

                const hideDeleteButton = () => {
                    const deleteButton = document.getElementsByClassName('dhx_delete_btn_set');;
                    deleteButton[0].style.display = 'none';
                }

                hideDeleteButton();

                const hideButtons = () => {
                    hideDeleteButton();
                    const cancelButton = document.getElementsByClassName('dhx_cancel_btn_set');
                    const saveButton = document.getElementsByClassName('dhx_save_btn_set');
                    const pushRight = document.getElementsByClassName('dhx_cal_lcontrols_push_right');
                    cancelButton[0].style.display = 'none';
                    saveButton[0].style.display = 'none';
                    pushRight[0].style.display = 'none';
                };

                if (event.classname === 'red') {
                    const controlsContainer = document.querySelector('.dhx_cal_lcontrols');
                    if (controlsContainer && !document.querySelector('.cancel-appointment-btn')) {
                        const cancelButton = document.createElement('div');
                        cancelButton.className = 'dhx_btn_set dhx_right_btn_set cancel-appointment-btn bg-red';
                        cancelButton.innerHTML = '<div>Cancel Appointment</div>';
                        cancelButton.onclick = () => {
                            cancelAppointment(true, event);
                        };
                        controlsContainer.appendChild(cancelButton);
                    }
                    hideButtons();
                }

                if (event.start_date < today) {
                    hideButtons();
                    const cancelAppointmentButton = document.getElementsByClassName('cancel-appointment-btn');
                    cancelAppointmentButton[0].style.display = 'none';
                }

                const checkboxInput = document.querySelector('input[name="Schedule Appointment"]');
                const reasonForVisitLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_Reason for visit');
                const reasonForVisit = document.getElementsByClassName('dhx_cal_ltext dhx_cal_select');
                const roomLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_Room number');
                const patientNameLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_PatientName');
                const commentsLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_comments');
                const scheduledByLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_Scheduled by');
                const providerEmailLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_provider email');
                const linkLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_my_link');
                linkLabel[0].style.display = 'none';
                const userIdLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_user_id');
                userIdLabel[0].style.display = 'none';
                const hospitalLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_hospital');
                hospitalLabel[0].style.display = 'none';
                const phoneLabel = document.getElementsByClassName('dhx_cal_lsection dhx_cal_lsection_phone');
                const textareas = document.querySelectorAll('textarea');
                const lastTextarea = textareas[textareas.length - 1];
                const secondLastTextarea = textareas[textareas.length - 2];
                if (lastTextarea) {
                    lastTextarea.style.display = 'none';
                    secondLastTextarea.style.display = 'none';
                }

                const disableSections = () => {
                    reasonForVisitLabel[0].style.display = 'none';
                    reasonForVisit[0].style.display = 'none';
                    roomLabel[0].style.display = 'none';
                    patientNameLabel[0].style.display = 'none';
                    commentsLabel[0].style.display = 'none';
                    scheduledByLabel[0].style.display = 'none';
                    providerEmailLabel[0].style.display = 'none';
                    phoneLabel[0].style.display = 'none';
                    manageDetailsElement.style.display = 'none';
                    refreshUsersElement.style.display = 'none';
                    const textareas = document.querySelectorAll('textarea');
                    const totalTextareas = textareas.length;
                    const startIndex = Math.max(0, totalTextareas - 7);
                    for (let i = startIndex; i < totalTextareas; i++) {
                        textareas[i].style.display = 'none';
                    }
                    scheduledBySection.control.style.visibility = 'hidden';
                }

                const enableSections = () => {
                    reasonForVisitLabel[0].style.display = 'flex';
                    reasonForVisit[0].style.display = 'block';
                    roomLabel[0].style.display = 'flex';
                    patientNameLabel[0].style.display = 'flex';
                    commentsLabel[0].style.display = 'flex';
                    scheduledByLabel[0].style.display = 'flex';
                    providerEmailLabel[0].style.display = 'flex';
                    phoneLabel[0].style.display = 'flex';
                    manageDetailsElement.style.display = 'block';
                    refreshUsersElement.style.display = 'block';
                    const textareas = document.querySelectorAll('textarea');
                    const totalTextareas = textareas.length;
                    const startIndex = Math.max(0, totalTextareas - 7);
                    for (let i = startIndex; i < totalTextareas; i++) {
                        textareas[i].style.display = 'block';
                    }
                    textareas[totalTextareas - 1].style.display = 'none';
                    textareas[totalTextareas - 2].style.display = 'none';
                    scheduledBySection.control.style.visibility = 'visible';
                }

                if (checkboxInput) {
                    if (checkboxInput.checked) {
                        enableSections();
                    } else {
                        disableSections();
                    }
                    checkboxInput.addEventListener('change', (event) => {
                        if (event.target.checked) {
                            enableSections();
                        } else {
                            disableSections();
                        }
                    });
                }

            } catch (error) {
                console.log('Error in onLightbox event:', error);
            }
        });

        scheduler.attachEvent("onEventSave", function (id, event, is_new) {
            showModal(true, id, event);
        });

    };

    const initScheduler = () => {
        const scheduler = schedulerInstance.current;
        if (!scheduler) {
            console.log('Scheduler instance is null in initScheduler');
            return;
        }

        scheduler.skin = 'terrace';
        scheduler.config.header = [
            'day',
            'week',
            'month',
            'date',
            'prev',
            'today',
            'next'
        ];

        scheduler.config.start_on_monday = false;
        scheduler.templates.event_class = function (start, end, event) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            if (start < today) {
                return "past-event";
            }
            return event.classname || "red";
        };
        scheduler.templates.event_text = function (start, end, event) {
            return event.text + '; ' + event.provider || "Default Text";
        };
        scheduler.templates.event_header = function (start, end, event) {
            return event.text + '; ' + event.provider || "Default Text";
        };

        scheduler.clearAll();
        scheduler.parse(events);
        scheduler.locale.labels.section_selectme = "Event Color";
        scheduler.plugins({
            editors: true
        });
        scheduler.config.first_hour = 6;
        scheduler.config.last_hour = 20;
        scheduler.config.time_step = 15;
        scheduler.config.hour_height = 100;
        scheduler.config.hour_size_px = 100;

        if (schedulerContainer.current) {
            scheduler.init(schedulerContainer.current, new Date(), "week");
        } else {
            console.log('Scheduler container ref is null');
        }
    };

    const refreshUsers = () => {
        updateScheduledByOptions();
    };


    return (
        <>
            {showStaffModal &&
                <div className="modal">
                    <div className="modalContent">
                        <div className="closeModalButton" onClick={() => { getStaffUsers(); updateScheduledByOptions(); setStaffModal(false); }}><Cross fill={'red'} /></div>
                        <StaffManager />
                    </div>
                </div >
            }
            <div className="mb-10" ref={schedulerContainer} style={{ width: '100%', height: '100%' }} />
        </>
    );
};

export default AppointmentSetter;